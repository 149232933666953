var site = window.site || {};

site.translations = site.translations || {};
site.translations.elc_general = site.translations.elc_general || {};
(function ($) {
  Drupal.behaviors.contentBlockSplitWidthCarouselFormatterV2 = {
    attach: function (context) {
      var $template = $('.js-split-width-carousel-formatter--v2', context);

      $('.js-content-block__container', context)
        .has('.mantle-media-asset')
        .addClass('split-width-carousel__media-container');

      $template.each(function () {
        var $self = $(this);
        var carousel = $self.find('.js-split-width-carousel');
        var dotsDiv = $self.find('.js-split-width-carousel-dots');
        var splitWidthSlide = '.js-split-width-carousel__slide';
        var slideSettings = $self.data() || {};
        var slidesToShow = slideSettings.slidesToShow || 1;
        var autoPlay = slideSettings.autoPlay || 3000;
        var infiniteCarousel = slideSettings.infinite;
        var autoplayDelay = slideSettings.autoplaySpeed * 1000;
        var settings = {
          asNavFor: carousel,
          adaptiveHeight: false,
          appendDots: dotsDiv,
          autoplay: autoPlay,
          autoplaySpeed: autoplayDelay,
          centerMode: false,
          dots: false,
          infinite: infiniteCarousel,
          nextArrow: $(this).parent().find('.slick-next-button'),
          prevArrow: $(this).parent().find('.slick-prev-button'),
          slide: splitWidthSlide,
          slidesToShow: slidesToShow,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                adaptiveHeight: false,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        $(carousel).slick(settings);
      });
    }
  };
})(jQuery);
